import { useSuspenseQuery } from "@tanstack/react-query"
import clsx from "clsx"
import { FC, SVGProps, Suspense } from "react"
import { ErrorBoundary } from "react-error-boundary"

export const iconCommonNames = [
  "audio-description",
  "arrow-down",
  "arrow-left",
  "arrow-right",
  "arrow-up",
  "audio",
  "calendar-details",
  "calendar",
  "caret-down",
  "caret-up",
  "chart",
  "check",
  "checkbox-false",
  "checkbox-true",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "chevron-up",
  "circle-small",
  "clock",
  "cloud-arrow-down",
  "cloud-arrow-up",
  "cloud-slash",
  "code-branch",
  "credit-card",
  "cross-big",
  "cross-circle",
  "cross",
  "display-down",
  "display",
  "download",
  "duplicate",
  "ear-deaf",
  "edit",
  "ellipsis",
  "error",
  "exit",
  "external",
  "exclamation",
  "eye",
  "globe",
  "grip",
  "headset",
  "history",
  "home",
  "info",
  "key",
  "library",
  "link",
  "list-check",
  "lock",
  "megaphone",
  "menu",
  "mini-setting",
  "pause",
  "play",
  "plus",
  "processing",
  "qrcode",
  "question-mark",
  "rocket",
  "rotate",
  "shield",
  "search",
  "setting",
  "share",
  "slider",
  "sort",
  "transcoding",
  "trash",
  "turn-down",
  "upload",
  "user",
  "video",
  "warning"
] as const
export const iconRegularNames = [...iconCommonNames] as const
export const iconSolidNames = [...iconCommonNames] as const
export type IconRegularName = (typeof iconRegularNames)[number]
export type IconSolidName = (typeof iconSolidNames)[number]

export type IconSize = 16 | 20 | 24
export type IconProps = {
  className?: string
  svgProps?: SVGProps<SVGSVGElement>
  size: IconSize
} & (
  | {
      type: "solid"
      icon: IconSolidName
    }
  | {
      type: "regular"
      icon: IconRegularName
    }
)

const sizeToClass = {
  16: "w-4 h-4",
  20: "w-5 h-5",
  24: "w-6 h-6"
}

const IconElement: FC<IconProps> = ({ icon, type, className, svgProps, size }) => {
  const { data: SvgIcon } = useSuspenseQuery({
    queryFn: async () => (await import(`./icons/${type}/ic_${icon}.svg?react`)).default,
    queryKey: ["icon", type, icon]
  })

  return (
    <div>
      <SvgIcon {...svgProps} className={clsx(sizeToClass[size], className)} />
    </div>
  )
}

const Icon: FC<IconProps> = props => (
  <Suspense fallback={<div className={clsx(sizeToClass[props.size], props.className)} />}>
    <ErrorBoundary
      fallbackRender={() => (
        <div className={props.className}>
          <div className="h-full w-full border border-grey-900" />
        </div>
      )}
    >
      <IconElement {...props} />
    </ErrorBoundary>
  </Suspense>
)

export default Icon
