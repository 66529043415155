/* eslint-disable sort-keys-fix/sort-keys-fix */
import { lazy } from "react"
import Icons from "./App/base/Icons"
import LinkRedundancyFlowStory from "./App/specific/LinkRedundancyFlow"

const App = lazy(() => import("./App"))

const Avatars = lazy(() => import("./App/base/Avatars"))
const Messages = lazy(() => import("./App/base/Messages"))
const Buttons = lazy(() => import("./App/base/Buttons"))
const Colors = lazy(() => import("./App/base/Colors"))
const Dividers = lazy(() => import("./App/base/Dividers"))
const Modals = lazy(() => import("./App/base/Modals"))
const Forms = lazy(() => import("./App/base/Forms"))
const Layout = lazy(() => import("./App/base/Layout"))
const Floaters = lazy(() => import("./App/base/Floaters"))
const Progress = lazy(() => import("./App/base/Progress"))
const Shadows = lazy(() => import("./App/base/Shadows"))
const Status = lazy(() => import("./App/base/Status"))
const Stepper = lazy(() => import("./App/base/Stepper"))
const Containers = lazy(() => import("./App/base/Container"))
const Tables = lazy(() => import("./App/base/Tables"))
const TabList = lazy(() => import("./App/base/TabList"))
const Tags = lazy(() => import("./App/base/Tags"))
const Tour = lazy(() => import("./App/base/Tour"))
const WiredForms = lazy(() => import("./App/wired/WiredForms"))
const Toasts = lazy(() => import("./App/base/Toasts"))

export const designSystemRoutes = {
  path: "design_system",
  element: <App />,
  children: [
    {
      children: [
        { path: "avatars", element: <Avatars /> },
        { path: "icons", element: <Icons /> },
        { path: "buttons", element: <Buttons /> },
        { path: "colors", element: <Colors /> },
        { path: "dividers", element: <Dividers /> },
        { path: "forms", element: <Forms /> },
        { path: "layout", element: <Layout /> },
        { path: "messages", element: <Messages /> },
        { path: "modals", element: <Modals /> },
        { path: "containers", element: <Containers /> },
        { path: "floaters", element: <Floaters /> },
        { path: "progress", element: <Progress /> },
        { path: "shadows", element: <Shadows /> },
        { path: "status", element: <Status /> },
        { path: "steppers", element: <Stepper /> },
        { path: "tables", element: <Tables /> },
        { path: "tabList", element: <TabList /> },
        { path: "tags", element: <Tags /> },
        { path: "toasts", element: <Toasts /> },
        { path: "tour", element: <Tour /> }
      ],
      path: "base"
    },
    {
      children: [{ element: <WiredForms />, path: "forms" }],
      path: "wired"
    },
    {
      children: [{ path: "linkRedundancyFlow", element: <LinkRedundancyFlowStory /> }],
      path: "specific"
    }
  ]
}
